import { GoalName, SkuTier } from '../__generated-gql-types__/globalTypes';

export const getTierDescription = (
  skuTier: SkuTier,
  goalName: Possible<GoalName>,
): string => {
  switch (skuTier) {
    case SkuTier.BASE:
      return 'Vetted coaches, students, and alumni new to Leland';
    case SkuTier.PRO:
      return goalName === GoalName.SCHOOL
        ? 'Skilled coaches with practical admissions experience'
        : 'Skilled coaches with practical industry experience';
    case SkuTier.ELITE:
      return 'Seasoned professionals with specialist knowledge';
    case SkuTier.ELITE_PLUS:
      return 'The top 1% of Leland’s coaches';
    default:
      return '';
  }
};

export const getTiersAtOrBelow = (tier: SkuTier) => {
  const tiers: SkuTier[] = [];
  /* eslint-disable no-fallthrough -- we want fallthrough to collect all tiers */
  switch (tier) {
    case SkuTier.ELITE_PLUS:
      tiers.push(SkuTier.ELITE_PLUS);
    case SkuTier.ELITE:
      tiers.push(SkuTier.ELITE);
    case SkuTier.PRO:
      tiers.push(SkuTier.PRO);
    case SkuTier.BASE:
      tiers.push(SkuTier.BASE);
  }
  /* eslint-enable no-fallthrough */
  return tiers;
};
